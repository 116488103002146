var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pb-2"},[_c('portal',{attrs:{"to":"tab-title"}},[_c('div',[_c('h4',{staticClass:"text-center font-weight-bolder"},[_vm._v(" "+_vm._s(_vm.$t('Catalog'))+" ")])])]),_c('l-table',{ref:"lTableRef",attrs:{"module-name":_vm.KITS_MODULE_NAME,"table-columns":_vm.tableKitListColumn,"create-page-url":{ name: 'home-kits-create' },"create-btn-dashed":true,"trash-list-path":"home-main-catalog-trash-list-kits","table-config-options":_vm.getTableConfigOptions(),"can-create":_vm.ACCESS_ABILITY_KITS,"can-show-trash-list":_vm.ACCESS_ABILITY_KITS},scopedSlots:_vm._u([{key:"cell(actionOf)",fn:function(ref){
var data = ref.data;
return [_c('div',{staticClass:"text-nowrap"},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.noninteractive.hover",modifiers:{"noninteractive":true,"hover":true}}],staticClass:"mr-1 cursor-pointer",attrs:{"icon":"LTrashIconKits","size":"32","title":"Set inactive"},on:{"click":function($event){return _vm.setInActive(data.item)}}}),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.noninteractive.hover",modifiers:{"noninteractive":true,"hover":true}}],staticClass:"cursor-pointer",attrs:{"icon":"LCopyIconKits","size":"32","title":"Duplicate"},on:{"click":function($event){return _vm.dublicateProduct(data.item)}}})],1)]}},{key:"cell(name)",fn:function(ref){
var data = ref.data;
return [_c('b-link',{staticClass:"text-dark text-wrap d-flex align-items-center",staticStyle:{"gap":"8px"},attrs:{"to":{ name: 'home-kits-view' , params: { id: data.item.id }}}},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.noninteractive.hover",modifiers:{"noninteractive":true,"hover":true}}],attrs:{"icon":"LKitsIcon","title":"View the kit","size":"26"}}),_c('span',[_vm._v(" "+_vm._s(data.item.name)+" ")])],1)]}},{key:"cell(is_active)",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(data.item.is_active === null ? '---' : data.item.is_active ? 'Active' : 'Inactive')+" ")]}},{key:"cell(assets)",fn:function(ref){
var data = ref.data;
return [_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(data.item.inventory_item_count ? data.item.inventory_item_count : '—')+" ")])]}},{key:"cell(discount)",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(data.item.discount)+"% ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }