<template>
  <div class="pb-2">
    <portal to="tab-title">
      <div>
        <h4 class="text-center font-weight-bolder">
          {{ $t('Catalog') }}
        </h4>
      </div>
    </portal>
    <l-table
      ref="lTableRef"
      :module-name="KITS_MODULE_NAME"
      :table-columns="tableKitListColumn"
      :create-page-url="{ name: 'home-kits-create' }"
      :create-btn-dashed="true"
      trash-list-path="home-main-catalog-trash-list-kits"
      :table-config-options="getTableConfigOptions()"
      :can-create="ACCESS_ABILITY_KITS"
      :can-show-trash-list="ACCESS_ABILITY_KITS"
    >
      <template
        #cell(actionOf)="{ data }"
      >
        <div
          class="text-nowrap"
        >
          <feather-icon
            v-b-tooltip.noninteractive.hover
            icon="LTrashIconKits"
            size="32"
            title="Set inactive"
            class="mr-1 cursor-pointer"
            @click="setInActive(data.item)"
          />
          <feather-icon
            v-b-tooltip.noninteractive.hover
            icon="LCopyIconKits"
            size="32"
            title="Duplicate"
            class="cursor-pointer"
            @click="dublicateProduct(data.item)"
          />
          <!--          <feather-icon-->
          <!--              icon="LPenIcon"-->
          <!--              class="cursor-pointer mr-1 border-dotted defaultIconColor"-->
          <!--              size="30"-->
          <!--              style="padding: 7px"-->
          <!--              @click="showProduct(data.item.id)"-->
          <!--          />-->
        </div>
      </template>
      <template #cell(name)="{ data }">
        <b-link
          :to="{ name: 'home-kits-view' , params: { id: data.item.id }}"
          class="text-dark text-wrap d-flex align-items-center"
          style="gap: 8px"
        >
          <feather-icon
            v-b-tooltip.noninteractive.hover
            icon="LKitsIcon"
            title="View the kit"
            size="26"
          />
          <span>
            {{ data.item.name }}
          </span>
        </b-link>
      </template>
      <template #cell(is_active)="{ data }">
        {{ data.item.is_active === null ? '---' : data.item.is_active ? 'Active' : 'Inactive' }}
      </template>
      <template #cell(assets)="{ data }">
        <div class="text-center">
          {{ data.item.inventory_item_count ? data.item.inventory_item_count : '—' }}
        </div>
      </template>
      <template #cell(discount)="{ data }">
        {{ data.item.discount }}%
      </template>
    </l-table>
  </div>
</template>

<script>

import { BLink, VBTooltip } from 'bootstrap-vue'
import LTable from '@/views/components/LTable/LTable.vue'
import config from '../kitsConfig'

export default {
  name: 'KitsList',
  components: {
    LTable,
    BLink,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      selected: true,
    }
  },
  methods: {
    refetchData() {
      this.$refs.lTableRef.refetchData()
    },
    setInActive(data) {
      this.confirmNotification(this, data, `${this.KITS_MODULE_NAME}/setInActive`,
        { text: 'It is possible to revert this', confirmButtonText: 'Yes, deactivate it' })
        .then(() => {
          this.refetchData()
        }).finally(() => {
          this.$store.dispatch('horizontalMenu/getWarehousesList')
        })
    },
    // remove(data) {
    //   this.confirmNotification(this, data, `${this.KITS_MODULE_NAME}/del`)
    //     .then(() => {
    //       this.refetchData()
    //     })
    // },
    // showProduct(data) {
    //   this.$router.push({ name: 'home-kits-view', params: { id: data } })
    // },
    update(data) {
      this.$router.push({
        name: 'home-kits-view',
        params: { id: data.id },
      })
    },
    dublicateProduct(data) {
      this.isLoading = true
      const newKit = this.mappingFields(Object.keys(this.fields), data, {
        name: data.name,
        products: data.products.map(product => ({
          id: product.id,
          qty: product.pivot.product_qty,
        })),
        is_active: String(data.is_active),
      })
      this.sendNotification(this, newKit, `${this.KITS_MODULE_NAME}/create`)
        .then(() => {
          this.clear()
        })
        .catch(err => {
          this.error = err.response?.data
        }).finally(() => {
          this.isLoading = false
          this.refetchData()
        })
    },
    getTableConfigOptions() {
      return {
        endpoint: `${this.KITS_MODULE_NAME}/getActiveKitsList`,
      }
    },
  },
  setup() {
    const KITS_MODULE_NAME = 'kits'
    const { tableKitListColumn, fields, ACCESS_ABILITY_KITS } = config()
    return {
      KITS_MODULE_NAME,
      tableKitListColumn,
      fields,
      ACCESS_ABILITY_KITS,
    }
  },
}
</script>
