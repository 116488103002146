import { L_ACTIVE_CHECK_LF_BOX, TEXT_INPUT } from '@/views/components/DynamicForm/constants'

export default function config() {
  const ACCESS_ABILITY_KITS = { action: 'change', subject: 'Main List' }
  const tableColumns = [
    {
      key: 'action',
      label: '',
    },
    {
      key: 'sku',
      label: 'SKU',
      style: { width: '100px', 'text-align': 'left', padding: '7px 21px' },
    },
    {
      key: 'name',
      label: 'Name',
      style: { width: '800px', 'text-align': 'left', padding: '7px 21px' },
    },
    {
      key: 'qty',
      label: 'Units',
      style: { width: '250px ', 'text-align': 'center' },
      tdClass: 'webkit-center',
      rules: 'max:1',
      options: {
        options: {
          numericOnly: true,
          pattern: '[1-9][0-9]*',
        },
      },
    },
    {
      key: 'rates',
      label: 'Rate (default)',
      style: { width: '200px', 'text-align': 'center' },
    },
    {
      key: 'discounted',
      label: 'Discounted',
      style: { width: '250px', 'text-align': 'center' },
    },
  ]
  const trashTableColumns = [
    {
      key: 'action',
      label: '',
      thStyle: { width: '150px' },
    },
    {
      key: 'name',
      label: 'Name',
      style: { width: '800px', 'text-align': 'left', padding: '7px 21px' },
    },
    {
      key: 'status',
      label: 'Status',
      style: { width: '800px', 'text-align': 'left', padding: '7px 21px' },
    },
    {
      key: 'rates',
      label: 'Rate (default)',
      style: { width: '200px', 'text-align': 'center' },
    },
    {
      key: 'discounted',
      label: 'Discounted',
      style: { width: '250px', 'text-align': 'center' },
    },
  ]
  const autoSuggestTableColumns = [
    {
      key: 'sku',
      label: 'SKU',
    },
    {
      key: 'name',
      label: 'Name',
    },
    {
      key: 'detail',
      label: 'Model',
      getValue: (value, data) => data.detail?.model,
    },
    {
      key: 'brand',
      label: 'Brand',
      getValue: (value, data) => data.brand?.name,
    },
    {
      key: 'category',
      label: 'Category',
      getValue: (value, data) => data.categories[0]?.name,
    },
  ]

  const tableKitListColumn = [
    { key: 'actionOf', label: '', thStyle: { width: '80px' } },
    {
      key: 'name',
      label: 'Name',
      isSortable: true,
    },
    {
      key: 'is_active',
      label: 'Status',
      thStyle: { width: '300px' },
    },
    {
      key: 'assets',
      label: 'Assets',
      thStyle: { width: '90px', textAlign: 'center' },
      isSortable: true,
    },
    {
      key: 'discount',
      label: 'Discount to apply (%)',
      style: { width: '500px' },
    },
  ]

  const fields = {
    name: {
      type: TEXT_INPUT,
      label: 'Kit Name',
      rules: 'required',
      placeholder: 'Type...',
    },
    is_active: {
      type: L_ACTIVE_CHECK_LF_BOX,
      rules: 'required',
    },
    discount: {
      type: TEXT_INPUT,
      label: 'Discount (as percentage) to apply',
      rules: 'positive',
      placeholder: 'Type...',
    },
  }

  return {
    fields,
    tableColumns,
    trashTableColumns,
    tableKitListColumn,
    autoSuggestTableColumns,
    ACCESS_ABILITY_KITS,
  }
}
